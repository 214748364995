/*
 * @Description: 
 * @Author: paddies
 * @Date: 2023-12-08 00:33:14
 * @LastEditors: paddies
 * @LastEditTime: 2024-01-26 17:52:16
 * @FilePath: /website-app/src/hook/useImageKey.ts
 */


import api from "@/api/api";
export async function useImageKey(key:string) {
        return await doGetImage(key);
}


const doGetImage=(key:string)=> {
    return new Promise((resolve, reject) => {
        api.mainAPI
        .queryImageListByKey({
          pathParams: {
            key: key,
          },
        })
        .then((res: any) => {
            console.log("接口发挥====",res)
          let data = res.data || [];
          let result = {} as any;
          data.forEach((item: any) => {
            let key = item.keyDesc;
            let value = item.imagesOssUrls;
            let remark = item.remark || "";
            let openUrl = item.openUrl || "";
            result[key] = value;
            result[key+"_REMARK"] = remark
            result[key+"_OPENURL"] = openUrl
          });
          resolve(result)
    
        });

    })
}