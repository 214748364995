export function RyderThree() {
	this.initScene = () => {
		this.scene = new THREE.Scene();
	}

	this.initCamera = () => {
		this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
		this.camera.position.set(0, 0, 23);
		// this.camera.lookAt(this.scene.position);
	}

	this.initRender = () => {
		this.renderer = new THREE.WebGLRenderer({
			canvas: document.getElementById('three_canvas'),
			antialias: true,
			alpha: true,
		});

		// this.renderer.setClearColor('#ccc');
	}

	this.initControls = () => {
		this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
	}

	this.mouseMoveHandler = (event) => {
		this.mouse.x = event.clientX - this.ww / 2;
		this.mouse.y = event.clientY - this.wh / 2;
	}

	this.resize = () => {
		this.ww = window.innerWidth;
		this.wh = window.innerHeight;

		this.camera.aspect = this.ww / this.wh;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(this.ww, this.wh);
	}

	this.initProperty = () => {
		this.debug = false;
		this.mouse = new THREE.Vector2();
		this.target = new THREE.Vector2();
		this.tube = '';
	}

	this.initObject = () => {
		var loader = new THREE.GLTFLoader();
		console.log("加载素材")
		loader.load('../models/tube.gltf', (gltf) => {
			var model = gltf.scene;
			model.position.set(-3, -9, 0);
			model.rotation.z = -20 * (Math.PI / 180);
			model.scale.set(0.045, 0.06, 0.045);
			this.scene.add(model);


			// 轉正
			gsap.to(model.rotation, {
				scrollTrigger: {
					trigger: ".automaticWrap",
					start: "top 50%",
					end: "bottom 50%",
					scrub: true,
					// markers: {
					// 	startColor: "red",
					// 	endColor: "red",
					// 	fontSize: "12px"
					// },
				},
				z: 0,
			});

			gsap.to(model.position, {
				scrollTrigger: {
					trigger: ".automaticWrap",
					start: "top 50%",
					end: "bottom 50%",
					scrub: true,
					// markers: {
					// 	startColor: "red",
					// 	endColor: "red",
					// 	fontSize: "12px"
					// },
				},
				x: -0.3,
			});


			var newMaterial = new THREE.MeshStandardMaterial({
				color: "#fff",
				// wireframe: true
			});

			model.traverse((node) => {
				if (node.isMesh) {
					node.material = newMaterial;
				}
			});
		});
	}

	this.initLight = () => {
		this.light = new THREE.PointLight('#fff', 0.6);
		this.light.position.set(8, 12, 6);
		this.scene.add(this.light);

		this.light2 = new THREE.PointLight('#fff', 0.6);
		this.light2.position.set(-8, 12, -6);
		this.scene.add(this.light2);

		var ambientLight = new THREE.AmbientLight('#c6c6c6', 0.8);
		this.scene.add(ambientLight);
	}

	this.update = (t) => {
		var _t = t || 0

		this.camera.position.x += (this.mouse.x * 0.01 - this.camera.position.x) * 0.1;
		this.camera.position.y += (-this.mouse.y * 0.02 - this.camera.position.y) * 0.1;

		this.camera.lookAt(this.scene.position);

		// this.tube.rotation.x += (this.target.y - this.camera.rotation.x) * 0.1;
		// this.tube.rotation.y += (this.target.x - this.camera.rotation.y) * 0.1;
	}

	this.render = (t) => {
		this.update(t);

		requestAnimationFrame(this.render);
		// this.controls.update();
		this.renderer.render(this.scene, this.camera);
	}

	this.init = () => {
		this.initScene()
		this.initCamera()
		this.initRender()
		// this.initControls()
		this.initProperty()
		this.initObject()
		this.initLight()
		this.render()

		if (this.debug) {
			var cameraHelper = new THREE.CameraHelper(this.camera);
			cameraHelper.matrixAutoUpdate = true
			this.scene.add(cameraHelper);

			var pointLightHelper = new THREE.PointLightHelper(this.light, 1);
			this.scene.add(pointLightHelper);
		}

		$(window).on("resize", () => {
			this.resize()
		}).trigger("resize")

		$(window).on("mousemove", (event) => {
			this.mouseMoveHandler(event)
		})
	}
}






export function RyderThree2() {
	this.initScene = () => {
		this.scene = new THREE.Scene();
	}

	this.initCamera = () => {
		this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
		this.camera.position.set(0, 0, 23);
		// this.camera.lookAt(this.scene.position);
	}

	this.initRender = () => {
		this.renderer = new THREE.WebGLRenderer({
			canvas: document.getElementById('three_canvas2'),
			antialias: true,
			alpha: true,
		});

		// this.renderer.setClearColor('#ccc');
	}

	this.initControls = () => {
		this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
	}

	this.mouseMoveHandler = (event) => {
		this.mouse.x = event.clientX - this.ww / 2;
		this.mouse.y = event.clientY - this.wh / 2;
	}

	this.resize = () => {
		this.ww = window.innerWidth;
		this.wh = window.innerHeight;

		this.camera.aspect = this.ww / this.wh;
		this.camera.updateProjectionMatrix();

		this.renderer.setSize(this.ww, this.wh);
	}

	this.initProperty = () => {
		this.debug = false;
		this.mouse = new THREE.Vector2();
		this.target = new THREE.Vector2();
		this.tube = '';
	}

	this.initObject = () => {
		var loader = new THREE.GLTFLoader();
		loader.load('../models/tube.gltf', (gltf) => {
			var model = gltf.scene;
			model.position.set(-3, -9, 0);
			model.rotation.z = -20 * (Math.PI / 180);
			model.scale.set(0.045, 0.06, 0.045);
			this.scene.add(model);


			// 轉正
			gsap.to(model.rotation, {
				scrollTrigger: {
					trigger: ".automaticWrap",
					start: "top 50%",
					end: "bottom 50%",
					scrub: true,
					// markers: {
					// 	startColor: "red",
					// 	endColor: "red",
					// 	fontSize: "12px"
					// },
				},
				z: 0,
			});

			gsap.to(model.position, {
				scrollTrigger: {
					trigger: ".automaticWrap",
					start: "top 50%",
					end: "bottom 50%",
					scrub: true,
					// markers: {
					// 	startColor: "red",
					// 	endColor: "red",
					// 	fontSize: "12px"
					// },
				},
				x: -0.3,
			});


			var newMaterial = new THREE.MeshStandardMaterial({
				color: "#fff",
				wireframe: true
			});

			model.traverse((node) => {
				if (node.isMesh) {
					node.material = newMaterial;
				}
			});

			// model.traverse((node) => {
			// 	if (node.isMesh) {
			// 		node.material.wireframe = true;
			// 	}
			// });
		});
	}

	this.initLight = () => {
		this.light = new THREE.PointLight('#fff', 0.6);
		this.light.position.set(8, 12, 6);
		this.scene.add(this.light);

		this.light2 = new THREE.PointLight('#fff', 0.6);
		this.light2.position.set(-8, 12, -6);
		this.scene.add(this.light2);

		var ambientLight = new THREE.AmbientLight('#3fa3f4', 0.6);
		this.scene.add(ambientLight);
	}

	this.update = (t) => {
		var _t = t || 0

		this.camera.position.x += (this.mouse.x * 0.01 - this.camera.position.x) * 0.1;
		this.camera.position.y += (-this.mouse.y * 0.02 - this.camera.position.y) * 0.1;

		this.camera.lookAt(this.scene.position);

		// this.tube.rotation.x += (this.target.y - this.camera.rotation.x) * 0.1;
		// this.tube.rotation.y += (this.target.x - this.camera.rotation.y) * 0.1;
	}

	this.render = (t) => {
		this.update(t);

		requestAnimationFrame(this.render);
		// this.controls.update();
		this.renderer.render(this.scene, this.camera);
	}

	this.init = () => {
		this.initScene()
		this.initCamera()
		this.initRender()
		// this.initControls()
		this.initProperty()
		this.initObject()
		this.initLight()
		this.render()

		if (this.debug) {
			var cameraHelper = new THREE.CameraHelper(this.camera);
			cameraHelper.matrixAutoUpdate = true
			this.scene.add(cameraHelper);

			var pointLightHelper = new THREE.PointLightHelper(this.light, 1);
			this.scene.add(pointLightHelper);
		}

		$(window).on("resize", () => {
			this.resize()
		}).trigger("resize")

		$(window).on("mousemove", (event) => {
			this.mouseMoveHandler(event)
		})
	}
}





export const  initFn = ()=>{

	// $(window).on("load", function () {

		console.log("初始化====")
	 	new RyderThree().init()

	
		new RyderThree2().init()
		// show
		gsap.from(".three_canvasWrap, .three_canvasWrap2", {
			scrollTrigger: {
				trigger: ".knowledgeWrap",
				start: "center 80%",
				toggleActions: "play pause resume reverse", //重覆觸發
				// markers: {
				// 	startColor: "green",
				// 	endColor: "green",
				// 	fontSize: "12px"
				// },
			},
			duration: 1,
			opacity: 0,
			y: -60,
		});
	
	
		// fixed
		ScrollTrigger.create({
			// trigger: ".app-container",
			trigger: ".three_canvasOutWrap",
			toggleActions: "play pause resume reverse", //重覆觸發
			start: "center 50%",
			endTrigger: ".qualityWrap",
			end: "bottom 50%", // no end
			pin: ".three_canvasOutWrap",
			pinSpacing: false,  //pin起來會預留空間這可以取消
			// markers: {
			// 	startColor: "green",
			// 	endColor: "red",
			// 	fontSize: "12px"
			// },
		});
	
	
		// change to wireframe
		gsap.to(".three_canvasWrap", {
			scrollTrigger: {
				trigger: ".automaticWrap",
				start: "top 50%",
				end: "center 50%",
				scrub: true,
				// markers: {
				// 	startColor: "green",
				// 	endColor: "green",
				// 	fontSize: "12px"
				// },
			},
			opacity: 1,
			bottom: $(window).height(),
		});
	
		gsap.to(".three_canvasWrap2", {
			scrollTrigger: {
				trigger: ".automaticWrap",
				start: "top 50%",
				end: "center 50%",
				scrub: true,
				// markers: {
				// 	startColor: "blue",
				// 	endColor: "blue",
				// 	fontSize: "12px"
				// },
			},
			opacity: 1,
			top: 0,
		});
	
	
	
		// change back to not wireframe
		gsap.to(".three_canvasWrap", {
			scrollTrigger: {
				trigger: ".servicesWrap .article-container",
				start: "top 10%",
				end: "200px",
				scrub: true,
				// markers: {
				// 	startColor: "green",
				// 	endColor: "green",
				// 	fontSize: "12px"
				// },
			},
			opacity: 1,
			bottom: 0,
		});
	
		gsap.to(".three_canvasWrap2", {
			scrollTrigger: {
				trigger: ".servicesWrap .article-container",
				start: "top 10%",
				end: "200px",
				scrub: true,
				// markers: {
				// 	startColor: "blue",
				// 	endColor: "blue",
				// 	fontSize: "12px"
				// },
			},
			opacity: 1,
			top: $(window).height(),
		});
	// })
}

