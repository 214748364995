/*
 * @Description: 
 * @Author: paddies
 * @Date: 2023-11-13 20:04:59
 * @LastEditors: paddies
 * @LastEditTime: 2024-01-19 18:39:46
 * @FilePath: /website-app/src/hook/useState.ts
 */
import { computed } from 'vue';
import { mapState, useStore } from 'vuex';
// mapper是存在state中的数据
export function useState(mapper: any) {
  const store = useStore();

  const storeStateFns = mapState(mapper);

  const storeState: any = {};
  Object.keys(storeStateFns).forEach((fnKey) => {
    const fn = storeStateFns[fnKey].bind({ $store: store });
    storeState[fnKey] = computed(fn);
  });

  return storeState;
}



export function useLanguageValue(key:string){
  const store = useStore();

  const storeStateFns = mapState({
    languageData: (state: any) => state.apps.languageData
  }) as any;
  const storeState: any = {};
  Object.keys(storeStateFns).forEach((fnKey:any) => {
    const fn = storeStateFns[fnKey].bind({ $store: store });
    storeState[fnKey] = computed(fn);
  });

  return storeState.languageData.value[key]

}


// languageCode: string,
  // 
export function useContextLanguage(key: string,
  item: any,
  isSpecial:boolean = false
) {
  const store = useStore();

  const storeStateFns = mapState({
    languageKey: (state: any) => state.apps.languageKey
  }) as any;
  const storeState: any = {};
  Object.keys(storeStateFns).forEach((fnKey:any) => {
    const fn = storeStateFns[fnKey].bind({ $store: store });
    storeState[fnKey] = computed(fn);
  });
 
  let languageCode = storeState.languageKey.value;
  if(isSpecial){
    key = key.substr(0, key.length - 2);
  }
  switch (languageCode) {
    case "cn": 
      if(isSpecial){
        key+="Cn"
      }
      return item[key];
    case "ft":
      return item[key + "Ft"];
    case "en":
      return item[key + "En"];
    case "rn":
      return item[key + "Rn"];
    case "fn":
      return item[key + "Fn"];

    default:
      break;
  }
}
