/*
 * @Author: zzh
 * @Date: 2022-08-30 18:32:24
 * @Last Modified by: zzh
 * @Last Modified time: 2022-08-30 18:32:487
 * @Description: 子应用存储仓库
 */


import api from '@/api/api';

export default {
  namespaced: true,
  state: {
    languageKey:'cn',
    languageData:{},
    isLoading: true, //构建等待
    loadApps: [] //已渲染apps
  },
  getters: {
    isLoading: (state: any) => state.isLoading
  },
  mutations: {
    addLoadApps(state: any, data: any) {
      if (state.loadApps.includes(data)) return;
      state.loadApps.push(data);
    },
    setLoading(state: any, data: any) {
      state.isLoading = data;
    },
    changeLanguage(state:any,data:any){
      state.languageKey = data;
      api.mainAPI.queryListLangeByCode({
          pathParams:{
              code:state.languageKey
          }
      }).then((res:any)=>{
        console.log("语言包返回值=====",res)
        res = res || []
        state.languageData = {};
        res.forEach((item:any) => {
          state.languageData[item.keyDesc] = item.value
        });


        console.log("本地语言包====>",state.languageData)
       
      })
    }
  },
  actions: {
    setLoading(store: any, data: any) {
      store.commit('setLoading', data);
    },
    addLoadApps(store: any, data: any) {
      store.commit('addLoadApps', data);
    },
    changeLanguage(store:any,data:any){
      store.commit("changeLanguage",data)
    }
  }
};
