import { generate } from '../utils/api-generator';

const requireAPI = require.context('@/api', true, /index+\.(ts)$/);
let apiList: any[] = [];
function importAll(r: any) {
  r.keys().forEach((item: any) => {
    // console.log(r(item).default);
    apiList.push(r(item).default);
  });
}
importAll(requireAPI);
const api: any = apiList;
export default generate(api);
