//传入key从url中取值
export function getQueryString(name: string) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  let r = window.location.search.substr(1).match(reg);
  if (r !== null) {
    return r[2];
  }
  return null;
}


//name 字段名   value 字段值   perpetual  有效期
export function setCookie(name:string,value:any,perpetual:any) {
  let exdate:any = new Date()
  exdate.setDate(exdate.getDate() + perpetual)  //exdate.setDate(exdate.getDate() + 365)
  document.cookie = name + '=' + value + ';expires=' + exdate.toGMTString()
  //永久有效
  //document.cookie = name + '=' + value + ';expires=' + 'Fri, 31 Dec 9999 23:59:59 GMT'    
}


//name 字段名   
export function getCookie(name:string) {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(name + '=')
    if (start !== -1) {
      start = start + name.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}

// 删除Cookie
export function removeCookie(name:any){
    setCookie(name, 1, -1)
}

//   // 文件导出
//   // 传入二进制文件流下载文件
//   export function createFile(dataStream, filename) {
//     let blob = new Blob([dataStream])
//     let link = document.createElement('a')
//     link.download = filename
//     link.href = URL.createObjectURL(blob)
//     link.click()
//     link.remove()
//   }

//   // 日期格式化
//   export function getyyyyMMdd() {
//     let d = new Date()
//     let curr_date = d.getDate()
//     let curr_month = d.getMonth() + 1
//     let curr_year = d.getFullYear()
//     String(curr_month).length < 2 ? (curr_month = '0' + curr_month) : curr_month
//     String(curr_date).length < 2 ? (curr_date = '0' + curr_date) : curr_date
//     let yyyyMMdd = curr_year + '' + curr_month + '' + curr_date
//     return yyyyMMdd
//   }

//   // 获取文件后缀
//   export function getFileType(filename) {
//     let index1 = filename.lastIndexOf('.')
//     let index2 = filename.length
//     let type = filename.substring(index1, index2)
//     return type.toUpperCase()
//   }

//   //把字节转换成文件大小
//   export function getFileSize(size) {
//     if (!size) return ''
//     let num = 1024.0 //byte
//     if (size < num) return size + 'B'
//     if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'KB' //kb
//     if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'M' //M
//     if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'g' //G
//     return (size / Math.pow(num, 4)).toFixed(2) + 'TB' //T
//   }

//   //图标处理
//   export function getFileIcon(file_name) {
//     let t_name = getFileType(file_name)
//     let icon = '#icon-wenjian'
//     let icon_list = {
//       '.PNG': '#icon-Png',
//       '.JPEG': '#icon-JPEG',
//       '.JPG': '#icon-jpg',
//       '.GIF': '#icon-GIF',
//       '.RAR': '#icon-RAR',
//       '.ZIP': '#icon-zip',
//       '.MP4': '#icon-MP4',
//     }
//     //图片类型
//     let reg_image = RegExp(/.GIT|.PNG|.JPEG|.JPG|.BMP|.SVG|.WEBP/)
//     if (t_name.match(reg_image)) {
//       icon = '#icon-jpg'
//       if (icon_list[t_name.match(reg_image)]) {
//         icon = icon_list[t_name.match(reg_image)]
//       }
//     }
//     //word类型
//     let reg_word = RegExp(/.DOC|.DOCX|.PDF/)
//     if (t_name.match(reg_word)) {
//       icon = '#icon-word'
//     }
//     //pdf类型
//     let reg_pdf = RegExp(/.PDF/)
//     if (t_name.match(reg_pdf)) {
//       icon = '#icon-pdf'
//     }
//     //excel类型
//     let reg_excel = RegExp(/.XLSX|.CSV/)
//     if (t_name.match(reg_excel)) {
//       icon = '#icon-excel'
//     }

//     //视频类型
//     let reg_video = RegExp(/.MP4|.MPEG|.FLV|.AVI|.MOV|.WMV|.3GP/)
//     if (t_name.match(reg_video)) {
//       icon = '#icon-video'
//       if (icon_list[t_name.match(reg_video)]) {
//         icon = icon_list[t_name.match(reg_video)]
//       }
//     }

//     //视频类型
//     let reg_audio = RegExp(/.MP3|.WMA/)
//     if (t_name.match(reg_audio)) {
//       icon = '#icon-mp'
//       if (icon_list[t_name.match(reg_audio)]) {
//         icon = icon_list[t_name.match(reg_audio)]
//       }
//     }

//     //压缩包类型
//     let reg_zip = RegExp(/.RAR|.ZIP/)
//     if (t_name.match(reg_zip)) {
//       if (icon_list[t_name.match(reg_zip)]) {
//         icon = icon_list[t_name.match(reg_zip)]
//       }
//     }

//     return icon
//   }


/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source:any) {
  if (!source && typeof source !== 'object') {
    // throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] as any : {} as any
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}
//   //深度复制
//   export function deepClone(values) {
//     let copy
//     // Handle the 3 simple types, and null or undefined
//     if (null === values || 'object' !== typeof values) return values

//     // Handle Date
//     if (values instanceof Date) {
//       copy = new Date()
//       copy.setTime(values.getTime())
//       return copy
//     }

//     // Handle Array
//     if (values instanceof Array) {
//       copy = []
//       for (let i = 0, len = values.length; i < len; i++) {
//         copy[i] = deepClone(values[i])
//       }
//       return copy
//     }

//     // Handle Object
//     if (values instanceof Object) {
//       copy = {}
//       for (let attr in values) {
//         if (Object.prototype.hasOwnProperty.call(values, attr)) copy[attr] = deepClone(values[attr])
//       }
//       return copy
//     }

//     throw new Error('Unable to copy values! Its type isn it supported.')
//   }

//   //能否json解析
//   export function isJSON(str) {
//     if (typeof str === 'string') {
//       try {
//         let obj = JSON.parse(str)
//         if (typeof obj === 'object' && obj) {
//           return true
//         } else {
//           return false
//         }
//       } catch (e) {
//         console.log('error：' + str + '!!!' + e)
//         return false
//       }
//     }
//   }

//   //拼接方法
//   export function splitDivision(arr = [], symbol = '-') {
//     let myarr = arr.filter((item) => {
//       return typeof item !== 'undefined' && item !== null && item !== ''
//     })
//     return myarr.join(symbol)
//   }

//   //判断是否空
//   export function isEmpty(val) {
//     // null or undefined
//     if (val === null || val === undefined) return true
//     if (typeof val === 'boolean') return false
//     if (typeof val === 'number') return !val
//     if (val instanceof Error) return val.message === ''
//     if (
//       Object.prototype.toString.call(val) === '[object String]' ||
//       Object.prototype.toString.call(val) === '[object Array]'
//     ) {
//       return !val.length
//     } else if (
//       Object.prototype.toString.call(val) === '[object File]' ||
//       Object.prototype.toString.call(val) === '[object Map]' ||
//       Object.prototype.toString.call(val) === '[object Set]'
//     ) {
//       return !val.length
//     } else if (Object.prototype.toString.call(val) === '[object Object]') {
//       return !Object.keys(val).length
//     }

//     return false
//   }

//   function isObject(obj) {
//     return Object.prototype.toString.call(obj) === '[object Object]'
//   }
//   function isArray(arr) {
//     return Array.isArray(arr)
//   }
//   export function merge(target, ...arg) {
//     return arg.reduce((acc, cur) => {
//       return Object.keys(cur).reduce((subAcc, key) => {
//         const srcVal = cur[key]
//         if (isObject(srcVal)) {
//           subAcc[key] = merge(subAcc[key] ? subAcc[key] : {}, srcVal)
//         } else if (isArray(srcVal)) {
//           // series: []，下层数组直接赋值
//           subAcc[key] = srcVal.map((item, idx) => {
//             if (isObject(item)) {
//               const curAccVal = subAcc[key] ? subAcc[key] : []
//               return merge(curAccVal[idx] ? curAccVal[idx] : {}, item)
//             } else {
//               return item
//             }
//           })
//         } else {
//           subAcc[key] = srcVal
//         }
//         return subAcc
//       }, acc)
//     }, target)
//   }

//   function hasClass(elements, cName) {
//     return !!elements.className.match(new RegExp('(\\s|^)' + cName + '(\\s|$)'))
//   }
//   //添加类样式
//   export function addClass(elements, cName) {
//     if (!hasClass(elements, cName)) {
//       elements.className += ' ' + cName
//     }
//   }
//   //移除类样式
//   export function removeClass(elements, cName) {
//     if (hasClass(elements, cName)) {
//       elements.className = elements.className.replace(new RegExp('(\\s|^)' + cName + '(\\s|$)'), ' ')
//     }
//   }

//   /**
//    * 得到浏览器显示的屏幕高度
//    */
//   export function getViewHeight() {
//     if (window.innerHeight !== window.undefined) return window.innerHeight
//     if (document.compatMode === 'CSS1Compat') return document.documentElement.clientHeight
//     if (document.body) return document.body.clientHeight
//     return window.undefined
//   }

//   /**
//    * 方法：对象数组排序（升序）
//    * 适用类型：数组内的元素为对象类型，并且对象中的排序条件可运算
//    * 用法：sortObjArray(数组，键名)
//    * 返回值：返回一个新的排序数组对象
//    **/
//   export function sortObjArray(array, mykey) {
//     function SortIdx(a, b) {
//       return a[mykey] - b[mykey]
//     }
//     let myarray = array
//     myarray.sort(SortIdx)
//     return myarray
//   }

//   //比较传入的对象是否全等，返回true则全等
//   export function deepCompare(x, y) {
//     if (x === y) {
//       return true
//     }

//     if (!(x instanceof Object) || !(y instanceof Object)) {
//       return false
//     }

//     if (x.constructor !== y.constructor) {
//       return false
//     }

//     for (let p in x) {
//       if (x.hasOwnProperty(p)) {
//         if (!y.hasOwnProperty(p)) {
//           return false
//         }
//         if (x[p] === y[p]) {
//           continue
//         }
//         if (typeof x[p] !== 'object') {
//           return false
//         }
//         if (!Object.equals(x[p], y[p])) {
//           return false
//         }
//       }
//     }

//     for (let p in y) {
//       if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
//         return false
//       }
//     }
//     return true
//   }

//   //最多保留小数位数，默认最多6位
//   export function customFix(num, retainNum = 6, hasRound = true) {
//     if (isNaN(num) || (!num && num !== 0)) return ''
//     num = Number(num)
//     const stringNum = num.toString()
//     if (stringNum.includes('.')) {
//       if (hasRound) {
//         return stringNum.split('.')[1].length > retainNum ? num.toFixed(retainNum) : num
//       } else {
//         return stringNum.split('.')[1].length > retainNum
//           ? Number(stringNum.substring(0, stringNum.lastIndexOf('.') + retainNum + 1))
//           : num
//       }
//     } else {
//       return num
//     }
//   }
