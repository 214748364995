<template>
    <div class="goods-image">
      <!-- 大图 -->
      <div v-show="show" class="large" :style="[{backgroundImage:`url(${images[currIndex]})`},largePosition]"></div>
      <!-- 中图 -->
      <!-- @click.stop="openImage()" -->
      <div   class="middle" ref="target">
        <img  v-if="!useHasMobile()"  :src="images[currIndex]" alt="">
        <el-image
          v-if="useHasMobile()"
          style="max-width: 100%;height: 100%"
          :src="images[currIndex]"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="[...images]"
          :initial-index="currIndex"
          fit="cover"
        />
        <!-- 遮罩色块 -->
        <div v-show="show" class="layer" :style="layerPosition"></div>
      </div>
      <!-- 小图 -->
      <ul class="small">
        <li v-for="(img,i) in images" :key="img" :class="{active:i===currIndex}">
          <img @mouseenter="currIndex=i" :src="img" alt="">
        </li>
      </ul>
    </div>
  </template>
  <script>
  import { reactive, ref, watch } from 'vue'
  import { useMouseInElement } from '@vueuse/core'
  import { useHasMobile } from "@/hook/useUtils";
  export default {
    name: 'HelloWorld',
    props: {
      images: {
        type: Array,
        default: () => [
          // 'https://yanxuan-item.nosdn.127.net/a6939f41c48fa9e9c8f7a7ed855351f1.jpg',
          // 'https://yanxuan-item.nosdn.127.net/0cdfd546f8675669b87716114ad5900a.jpg',
          // 'https://yanxuan-item.nosdn.127.net/240983ccc935146a4795e3990d30468d.jpg',
          // 'https://yanxuan-item.nosdn.127.net/d46e005025a5d3b73c4781d31b327558.jpg',
          // 'https://yanxuan-item.nosdn.127.net/330913911087b44b0d817dd78233165f.png',
        ]
      }
    },
    setup (props) {
      // 当前预览图的索引
      const currIndex = ref(0)
  
      // 1. 是否显示遮罩和大图
      const show = ref(false)
      // 2. 遮罩的坐标(样式)
      const layerPosition = reactive({
        left: 0,
        top: 0
      })
      // 3. 大图背景定位(样式)
      const largePosition = reactive({
        backgroundPositionX: 0,
        backgroundPositionY: 0
      })
      // 4. 使用useMouseInElement得到基于元素左上角的坐标和是否离开元素数据
      const target = ref(null)
      const { elementX, elementY, isOutside } = useMouseInElement(target)

     
      watch([elementX, elementY, isOutside], () => {
        if(useHasMobile()){
          return;
        }
        // 5. 根据得到数据设置样式数据和是否显示数据
        show.value = !isOutside.value
        // 计算坐标
        const position = { x: 0, y: 0 }
  
        if (elementX.value < 100) position.x = 0
        else if (elementX.value > 300) position.x = 200
        else position.x = elementX.value - 100
  
        if (elementY.value < 100) position.y = 0
        else if (elementY.value > 300) position.y = 200
        else position.y = elementY.value - 100
        // 给样式赋值
        layerPosition.left = position.x + 'px'
        layerPosition.top = position.y + 'px'
        largePosition.backgroundPositionX = -2 * position.x + 'px'
        largePosition.backgroundPositionY = -2 * position.y + 'px'
      })
  
      return { currIndex, show, layerPosition, largePosition, target,useHasMobile }
    }
  }
  </script>
  <style scoped lang="scss">
  ::v-deep .el-image-viewer__wrapper{
    z-index:9999 !important;
  }
  .goods-image {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    /* z-index: 500; */
    .large {
      position: absolute;
      top: 0;
      /* left: 412px; */
      left: calc(100% + 12px);
      width: 400px;
      height: 400px;
      box-shadow: 0 0 10px rgba(0,0,0,0.1);
      background-repeat: no-repeat;
      background-size: 800px 800px;
      background-color: #f8f8f8;
    }
    flex-direction: column;
    .middle {
      width:400px;
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      /* background-color: red; */
      position: relative;
      cursor: move;
      img{
        max-width: 100%;
        max-height: 100%;
      }
      //cursor: pointer;
      .layer {
        width: 200px;
        height: 200px;
        background: rgba(0,0,0,.2);
        left: 0;
        top: 0;
        position: absolute;
      }
    }
    .small {
      /* width: 80px; */
      float: left;
      display: flex;
      li {
        flex: 1;
        /* width: 68px; */
        /* height: 68px; */
        /* margin-left: 12px; */
        margin-bottom: 15px;
        /* display: inline-block; */
        float: left;
        cursor: pointer;
        img{
          max-width: 100%;
          max-height: 100%;
        }
        &:hover,&.active {
          border: 2px solid #27BA9B;
        }
      }
    }
  }
  </style>