import store from '@/store';

export function useActionsList(options: any): any {
  let opt: any = options || {};
  let map: any = {};

  for (let moduleName in opt) {
    map[moduleName] = useActions(moduleName, opt[moduleName]);
  }

  return map;
}

function useActions(moduleName: String, opt: any) {
  opt = opt || [];
  let map: any = {};
  opt.forEach((funName: any) => {
    let path = moduleName + '/' + funName;
    map[funName] = doCommit.bind({ path: path });
  });

  return map;
}

function doCommit(this: any, params: any) {
  let { path } = this;
  store.commit(path, params);
}
