/*
 * @Description: 
 * @Author: paddies
 * @Date: 2022-11-03 00:25:46
 * @LastEditors: paddies
 * @LastEditTime: 2023-12-25 14:53:58
 * @FilePath: /website-app/src/store/index.ts
 */
import { createStore } from 'vuex';
// import {data} from "@/store/data";
import router from '@/router/index';

import mutations from './mutations';
import actions from './actions';

// import user from './modules/user/index';
// import menu from './modules/menu/index';
import apps from './modules/apps/index';
// import home from './modules/home/index';

export default createStore({
  state: {
    // urlPath:"",//路由地址
    // menuManage:{
    //   list:data.data,//菜单数据
    //   hideLeftMenu:['homePage','mergeReportCenter','mergePlatformManagement'],//隐藏左边菜单的menuId
    //   // modulePath:"",
    //   clickModuleID:"",//当前点中模块id
    //   clickMenuID:"",//当前点中的菜单id,
    //   menuCache:[] as any[],//菜单缓存
    // }
  },
  //同步方法，最终修改全局对象数据
  mutations,
  //可编写异步方法，写一些相关逻辑，调用mutations中的方法执行
  actions,
  //modules：更复杂的对store进行局部拆分。
  modules: {
    // user,
    // menu,
    apps,
    // home
  }
});
