<!--
 * @Description: 
 * @Author: paddies
 * @Date: 2023-06-04 13:32:54
 * @LastEditors: paddies
 * @LastEditTime: 2024-04-23 17:21:12
 * @FilePath: /website-app/src/components/upload-file/index.vue
-->
<template>
 <div class="upload-file">
    <el-upload
          :action="getActionUrl()"
          list-type="picture-card"
          :headers="getHeaders()"
          :multiple="multiple"
          :limit="limit"
          :file-list="fileList"
          :on-success="(res) => onSuccessUpload(res)"
          :on-exceed="onExceed"
          :on-progress="onProgress"
          :on-error="onError"
          :auto-upload="true"
          :disabled="disabled"
          :class="{hide:fileList.length >= limit}"
        >
          <i  ref="btn" slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <!-- <video v-if="['mp4'].includes(getFileExtension(file.url))" :src="file.url" autoplay loop  muted style="position: absolute;top: 0;bottom: 0;margin: auto;width: 100%;height: 100%;"/> -->
            <!-- <img v-else  class="el-upload-list__item-thumbnail" :src="file.url" alt=""  style="position: absolute;top: 0;bottom: 0;margin: auto;"/> -->

            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>


        <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="true" append-to-body style="z-index: 2005 !important;">
          <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

 </div>
</template>
<script>
  // import { getToken } from '@/utils/auth';
export default {
  name: "",
  props:{
    disabled:{
      type:Boolean,
      default:false
    },
    multiple:{
      type:Boolean,
      default:false,
    },
    limit:{
      type:Number,
      default:1
    },
    filteGroup:{
        type:Array,
        default:()=>{
            return []
        }
    }
  },
  watch:{
    filteGroup:{
      handler(val, oldVal){
        if(JSON.stringify(val) != JSON.stringify(oldVal)){
          let d = JSON.parse(JSON.stringify(val.filter(t=>t).map(t=>{
                      return {
                          url:t
                      }
                  }))) || []
                  this.fileList = d
        }
       
        // let d = JSON.parse(JSON.stringify(this.filteGroup.filter(t=>t).map(t=>{
        //             return {
        //                 url:t
        //             }
        //         }))) || []
        //         console.log("处罚啊啊啊，",val)
        //         this.fileList = d
                // this.$set(this,'fileList',d)
      },
    //   deep:true
    } 
  
  },
  data() {
    return {
      uploadLoading:null,
      dialogImageUrl: '',
        dialogVisible: false,
        fileList:[],
        fileList2:[]
    };
  },
  mounted(){
    console.log("初始化的时候====",this.filteGroup)
         let d = JSON.parse(JSON.stringify(this.filteGroup.filter(t=>t).map(t=>{
                    return {
                        url:t
                    }
                }))) || []
                this.fileList = d

                // this.fileList2 = JSON.parse(JSON.stringify(d))
  },
  methods:{
     getFileExtension(filePath) {
        // 使用正则表达式获取文件名部分
        var fileName = filePath.replace(/^.*[\\\/]/, '');

        // 使用正则表达式获取后缀名部分
        var extension = (/[.]/.exec(fileName)) ? /[^.]+$/.exec(fileName)[0] : undefined;

        return extension;
      },
    

  
    handleRemove(file){
      console.log("删除开始")
        console.log(this.fileList,file)
        let index = this.fileList.findIndex(t=>t.url == file.url);
        if(index != -1){
            this.fileList.splice(index,1)
        }
        console.log('删除候===',this.fileList)

        this.$emit("onRemove",index)
    },
    handlePictureCardPreview(file){
      console.log("预览按钮====",file)
      this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    },
    getActionUrl() {
      return process.env.VUE_APP_BASE_API + '/oss/upload';
    },
    getHeaders() {
      return {
        Authorization: 'Bearer '
        // Authorization: 'Bearer ' + getToken()
      };
    },
    onError(){
        if(this.uploadLoading){
          this.uploadLoading.close()
        }

        this.$message.error('上传出错！');
      },
    onProgress(event,file){
        this.uploadLoading = this.$loading({
          lock: true,
          text: '上传处理中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        console.log(event,file)
      },
    onSuccessUpload(response) {
      if(this.uploadLoading){
          this.uploadLoading.close()
        }
      console.log("内部上传返回值：",response)
        let { code, data } = response;
        if (code != 200) {
          this.$message.error('图片上传失败！');
          return;
        }
        let { ossId, url } = data || {};
        // let d = []
        if(this.fileList.length < this.limit){
          // d.push({
          //   url: url
          // })
          // setTimeout(() => {
          //   this.fileList.push({
          //     url: url
          //   })
          // }, 300);
          // this.$nextTick(()=>{
          //   this.fileList.push({
          //     url: url
          //   })
          // })
          this.$emit("onSuccess",data);
        }
        this.fileList = []
        // this.fileList = JSON.parse(JSON.stringify(this.fileList.concat(d))) 

        // scope.imgUrl = ossId;
        // this.fileList = [
        //   {
        //     url: url
        //   }
        // ];
       
      },
      onExceed() {
        if(this.uploadLoading){
          this.uploadLoading.close()
        }
      this.$message.warning('最多上传'+this.limit+'张图片！');
    },
    openFn(){
        this.$refs.btn.click()
       },
  }
};
</script>
<style lang="scss" scoped>
::v-deep .hide .el-upload--picture-card {
  display: none;
}

</style>