/*
 * @Description: 
 * @Author: paddies
 * @Date: 2023-12-07 15:00:07
 * @LastEditors: paddies
 * @LastEditTime: 2023-12-25 14:55:56
 * @FilePath: /website-app/src/hook/useWow.ts
 */


// import WOW from "wow.js";

const WOW = require('wow.js');
import 'wow.js/css/libs/animate.css'

import { onBeforeUnmount } from "vue"

export const useWow = async (scrollContainer:string,callback:Function = ()=>{})=>{
         var wow = new WOW({
        boxClass: "wow", // animated element css class (default is wow)
        animateClass: "animated", // animation css class (default is animated)
        offset: 0, // distance to the element when triggering the animation (default is 0)
        mobile: true, // trigger animations on mobile devices (default is true)
        live: true, // act on asynchronously loaded content (default is true)
        callback: function (box:any) {
          callback(box)
          // the callback is fired every time an animation is started
          // the argument that is passed in is the DOM node being animated
        },
        scrollContainer: scrollContainer, // optional scroll container selector, otherwise use window,
        resetAnimation: true, // reset animation on end (default is true)
      });
      
      wow.init();

      let loop = null as any;

      onBeforeUnmount(()=>{
        clearTimeout(loop);
      })

      // if(scrollContainer == '#index-body'){
        loopFn()
      // }

      function loopFn(){
        loop = setTimeout(() => {
          // console.log("执行")
          wow.scrollHandler();
          loopFn();
        }, 200);
       }
 }


