/*
 * @Description: 
 * @Author: paddies
 * @Date: 2023-12-05 14:53:44
 * @LastEditors: paddies
 * @LastEditTime: 2023-12-18 01:17:35
 * @FilePath: /website-app/src/hook/useAnchor.ts
 */


import { useRoute, useRouter } from "vue-router";

import {
    watch,
  } from "vue";
export function useAnchor(): any {
    const route = useRoute();
    const router = useRouter();

    watch(() => route?.query,
    () => {
        let { anchor } = route?.query || {}
        if(anchor){
            setTimeout(() => {
                
                doAnchor(anchor);
            }, 200);
        }
    }, {deep: true});
    let { anchor } = route?.query || {}
    // alert(anchor)
    if(anchor){
        setTimeout(() => {
            doAnchor(anchor);
        },200)
    }




}

function doAnchor(anchor:any){
    console.log("滚动=====",anchor)
    document.querySelector('#'+anchor)?.scrollIntoView({
        behavior: "smooth"
      });
}