// import request from "../utils/request";
import request  from "./request";

// import domain from "@/SDK/api/domain.js"

let getMethodType = function (method:string) {
    if (method && ['GET', 'POST', 'PUT', 'DELETE'].includes(method)) {
      return method;
    }
    let methodName;
    if (method.startsWith('get')) {
        methodName = 'GET';
    } else if (method.startsWith('create')) {
        methodName = 'POST';
    } else if (method.startsWith('update')) {
        methodName = 'PUT';
    } else if (method.startsWith('delete')) {
        methodName = 'DELETE';
    } else {
      throw new Error('method name is defined must be start with "get, create, update, delete"');
    }
    return methodName;
  };


  let getPathParams = function (url:string) {
    let regex = /{([^}]+)}/g;
    let pathParams = [];
    let currentMatch;
    while (currentMatch = regex.exec(url)) {
      pathParams.push(currentMatch[1]);
    }
    return pathParams;
  }
  

let prepareAjaxMethod = (api:any) => (options:any = {}) => {
    let method = getMethodType(api.method);
    let pathParams = getPathParams(api.url);
    let newUrl = ""
    try{
        newUrl = pathParams.reduce((url:string, pathParam:any) => {
          let paramVal = options.pathParams[pathParam];
          if (paramVal != undefined) {
              return url.replace(`{${pathParam}}`, paramVal);
            
          } 
          else {
            throw new Error(`${url} has invalid path params.`);
          }
        }, api.url);
    }catch(e){
        console.log(e, 999)
    }
    options.url = newUrl
    options.data = options.data || {}
    options.method = method
    options.noProxy = api.noProxy
    return request(options);
    // return new request().http(options);
  };

  

export const generate = function (apiObj:any[]) {
    let apiInfo:any = {}
    apiObj.forEach((item:any)=> {
        if('key' in item){
            let apiKey = item.key
            if(!(apiKey in apiInfo)){
                apiInfo[apiKey] = {}
            }
            item.list.forEach((apiItem:any)=>{
                apiInfo[apiKey][apiItem.name] = prepareAjaxMethod(apiItem);
            })
        }
    });
    return apiInfo;
  };
