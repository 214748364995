/*
 * @Description: 
 * @Author: paddies
 * @Date: 2023-11-30 18:15:04
 * @LastEditors: paddies
 * @LastEditTime: 2023-12-05 01:19:09
 * @FilePath: /website-app/src/hook/useDict.ts
 */

import api from "@/api/api";
// import { deepClone } from '@/utils/index';
export const useDict = async (type:string)=>{
    return await doGetDict(type)
 }
 

 const doGetDict=(type:string)=> {
    return new Promise((resolve, reject) => {
      // 如果本地已经有这个字典了，直接走缓存
      // let dict = store.state.system.dict[type];
      // console.log(dict)
      // if(dict){
      //   console.log( `${type}---字典已存在,走缓存`);
      //   resolve(deepClone(dict));
      //   return;
      // }
      api.mainAPI.getDicts({
        pathParams:{
            type:type
        }
      }).then((res:any) => {
        let { data } = res;
        data = data || [];
        data = data.map((item:any) => {
          return {
            label: item.dictLabel,
            value: item.dictValue,
            row: item,
          };
        });
        // store.commit("setSystemDict",{
        //   key:type,
        //   value:data
        // })
        console.log("字典写入完成====",type)
        resolve(JSON.parse(JSON.stringify(data)));
      });
    }).catch((_) => {
      return []
    });
}