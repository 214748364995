/*
 * @Description: 
 * @Author: paddies
 * @Date: 2022-11-03 00:25:06
 * @LastEditors: paddies
 * @LastEditTime: 2024-01-04 02:30:49
 * @FilePath: /website-app/src/main.ts
 */
import { createApp, inject } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import 'flag-icon-css/css/flag-icons.css';
import 'element-plus/dist/index.css';

import './assets/iconfont/iconfont.css'
// import './assets/styles/index.scss' // global css 

// import 'animate.css';
// 在main.ts中
// 使用animate.css的方式
// import "animate.css/animate.min.css";
// 使用wow中animate的方式
// import "wow.js/css/libs/animate.css";
// import wow from 'wowjs'
// import "wowjs/css/libs/animate.css"
// Vue.prototype.$wow = wow

// import animated from "animate.css";

import 'wow.js/css/libs/animate.css'

import AnimatedNumber from 'animated-number-vue3'
import FixedTools from "@/components/fixed-tools/index.vue";
import RotateIcon from "@/components/rotate-icon/index.vue";



const app = createApp(App)
app.provide('$',inject('$'));
// 2：全局注册组件
app.component('FixedTools', FixedTools)
app.component('RotateIcon', RotateIcon)
app.use(store).use(AnimatedNumber).use(router).use(ElementPlus).mount('#app');



