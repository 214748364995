/*
 * @Description:
 * @Author: paddies
 * @Date: 2023-12-26 17:11:20
 * @LastEditors: paddies
 * @LastEditTime: 2024-04-26 12:04:41
 * @FilePath: /website-app/src/hook/useUtils.ts
 */


import api from "@/api/api";
import { ElMessage } from 'element-plus';
// const handleSelectPageList = ()=>{
 
// }

export function useDownBook(languageKey: string): any {
  api.mainAPI.selectPageList().then((res:any)=>{
    console.log("lala",res)
    let {code,data} = res;
    if(code == 200){
      console.log("当前语言====",languageKey)
      console.log("下载列表=====",data)
      let index = data.findIndex((item:any)=>{
        return item.remark == languageKey;
      })
      if(index == -1){
        console.log("没有该语言")
        ElMessage.error("缺少当前语言目录！");
        return;
      }
      let {imagesUrl} = data[index];

      window.open(imagesUrl);
      // downloadList.value = data;
    }
  })
  // switch (languageKey) {
  //   case "cn":
  //     window.open("/pro/cn.pdf");
  //     break;
  //   case "rn":
  //     window.open("/pro/rn.pdf");
  //   default:
  //     window.open("/pro/en.pdf");
  //     break;
  // }
}

export function useFileExtension(f: string) {
  console.log("======", f);
  if (!f) {
    return undefined;
  }
  // 使用正则表达式获取文件名部分
  var fileName = f.replace(/^.*[\\\/]/, "");

  // var d = /[^.]+$/.exec(fileName)
  // 使用正则表达式获取后缀名部分
  var extension = /[.]/.exec(fileName);
  var d = /[^.]+$/.exec(fileName);
  if (extension) {
    return d![0];
  }

  return undefined;
}

//  判断是否为移动端
export function useHasMobile() {
  let isMobile = false;
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  ) {
    // console.log('移动端');
    isMobile = true;
  }
  if (document.body.clientWidth < 800) {
    isMobile = true;
  }
  return isMobile;
}

export function useContextLanguage(
  languageCode: string,
  key: string,
  item: any
) {
  switch (languageCode) {
    case "cn":
      return item[key];
    case "ft":
      return item[key + "Ft"];
    case "en":
      return item[key + "En"];
    case "rn":
      return item[key + "Rn"];
    case "fn":
      return item[key + "Fn"];

    default:
      break;
  }
}
