import axios from 'axios';
import qs from 'qs';
import { ElMessage } from 'element-plus';

import { getQueryString } from '@/utils/index';

import { ElLoading } from 'element-plus';
import {getCookie,removeCookie} from '@/utils/index';
// import { route } from 'vue-router';
import router from "@/router";
// const router = useRouter();

const instance: any = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
});



/** 请求拦截器 */
instance.interceptors.request.use(
  (config: any) => {
    if (!config.headers) {
      config.headers = {
        'content-type': 'application/json'
      };
    }
    if (config.loading) {
      if (!instance['loading']) {
        //开启等待
        instance['loading'] = ElLoading.service({
          lock: true,
          text: 'text' in config.loading ? config.loading.text : 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
    }
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    if (config.uToken) {
      config.headers['utoken'] = getQueryString('utoken'); // 获取urltoken
    }
    config.headers['Authorization'] = 'Bearer '+ getCookie('access_token')
    // if (store.state.userInfo) {
    //   config.headers['accessToken'] = store.state.userInfo.accessToken
    // }
    if (config.method === 'get') {
      config.paramsSerializer = function (params: any) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
    }

    return config;
  },
  (error: any) => Promise.reject(error)
);

/** 返回拦截器 */
instance.interceptors.response.use(
  (response: any) => {
    const { config } = response;
    if ('loading' in instance) {
      instance['loading'].close();
      delete instance['loading'];
    }
    if (response.status != 200) {
      return Promise.reject(response);
    }
  let {code} = response.data;
  if(code == 401){
    ElMessage.error(response.data.msg);
    //清除cookies
    removeCookie('access_token');
    router.push({
      name:"BasePage"
    })
    return
  }
    return response.data;
    //   // 是文件流的直接返回
    //   if (config['responseType'] === 'blob') {
    //     return response.data
    //   }
    //   if (response.data.code === 0) {
    //     const { data } = response
    //     return data
    //   } else {
    //     ElMessage.error(response.data.msg)
    //     return Promise.reject(response)
    //     // throw new Error(response)
    //   }
    // } else {
    //   ElMessage.error('哎呀，服务器出错啦')
    //   return Promise.reject(response)
    // }
  },
  (error: any) => {
    // console.log('错误返回值----', error);
    if (error.response.status === 401) {
      // console.log('未登录---', window);
      removeCookie('access_token');
      router.push({
        name:"BasePage"
      })
      // toLogin();
      return;
    }
    ElMessage.error('哎呀，服务器出错啦');
    return Promise.reject(error);
  }
);

const request = instance;
export default request;

function toLogin() {
  if (!window.config.sso) {
    ElMessage.error('哎呀，sso走丢了！无法登陆');
    return;
  }
  if (location.href.includes('/bip')) {
    ElMessage.error('哎呀，不是bip登陆！');
    return;
  }
  document.cookie = 'SESSION=;  expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  window.location.href =
    '/coframe/login?redirect_uri=' +
    window.config.appLocation +
    '/coframe/login&main_url=' +
    encodeURIComponent(window.location.href);
}
